import React from "react";
import Layout from "../../layout/layout.component";
import PropTypes from "prop-types";
import SEO from "../../partials/seo/seo.component";
import { graphql } from 'gatsby';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Discounts from "../../partials/discounts/discounts.component";

const DiscountsPost = (props) => {
    const discount = props.data.wordpressPage,
        { schema } = props.pageContext,
        discounts = JSON.parse(discount.discounts)[0];

    return (
        <Layout>
            <SEO data={schema}/>
            <Container>
                <Row className="justify-content-center discounts-list">
                    <div className="col-11 col-md-9 col-lg-8 col-xl-6">
                        <h1 className="list-heading">{discount.title}</h1>
                        <div className="list-text" dangerouslySetInnerHTML={{__html: discount.content}}></div>
                        <Discounts data={discounts}/>
                    </div>
                </Row>
            </Container>
        </Layout>
    );
};

DiscountsPost.propTypes = {
    pageContext: PropTypes.shape({
        schema: PropTypes.object.isRequired,
        id: PropTypes.string.isRequired,
    }),
    data: PropTypes.shape({
        wordpressPage: PropTypes.object.isRequired
    }),
};

export default DiscountsPost

export const pageQuery = graphql`
    query($id: String) {
        wordpressPage(id: { eq: $id }) {
             title
            content
            front_template
            small_date
            discounts

        }
    }
`;
