import React from "react";

const CouponBody = ( props ) => {
    return (
        <div className={props.className}>
            <svg width="100%" height="100%" viewBox="0 0 474 113" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M473.5 9.5C468.421 9.5 464.273 5.51428 464.013 0.5H5C2.51471 0.5 0.5 2.51472 0.5 5V102C0.5 104.485 2.51474 106.5 5 106.5H464.013C464.273 101.486 468.421 97.5 473.5 97.5V9.5Z" fill="white" stroke="#373B4B"/>
                <text fill="#373B4B" xmlSpace="preserve" style={{whiteSpace: 'pre', fontFamily: 'Nunito-Bold', fontSize: '27px', fontWeight:'bold', letterSpacing: '0em'}}>
                    <tspan x="30" y="48.383">{props.title}</tspan>
                </text>
                <text fill="#373B4B" xmlSpace="preserve" style={{whiteSpace: 'pre', fontFamily: 'Nunito-Regular', fontSize: "21px", fontWeight: "300", letterSpacing: '0em'}}>
                    <tspan x="30" y="79.409">{props.text}</tspan>
                </text>
            </svg>
        </div>
    );
}

export default CouponBody;
