import React from "react";

const CouponRoot = ( props ) => {
    return (
        <div className={props.className}>
            <svg width="100%" height="100%" viewBox="0 0 145 113" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0.5 9.5C5.57899 9.5 9.72706 5.51428 9.98707 0.5H140C142.485 0.5 144.5 2.51472 144.5 5V102C144.5 104.485 142.485 106.5 140 106.5H9.98707C9.72706 101.486 5.57899 97.5 0.5 97.5V9.5Z" fill={props.fill} stroke="#373B4B"/>
                <text fill="white" xmlSpace="preserve" style={{whiteSpace: 'pre', fontFamily: 'Nunito-Bold', fontSize: '27px', fontWeight:'bold', letterSpacing: '0em'}}>
                    <tspan x="43" y="43.383">{props.discount}</tspan>
                    <tspan x="43" y="80.383">OFF</tspan>
                </text>
            </svg>
        </div>
    );
}

export default CouponRoot;
