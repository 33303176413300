import React from "react";
import PropTypes from "prop-types";
import CouponBody from "../../../static/icons/couponBody";
import CouponRoot from "../../../static/icons/couponRoot";

const Discounts = (props) => {
    const hideCouponRoot = (data, event) => {
        let element = event.currentTarget;

        element.querySelector(".root").classList.add("d-none");
        element.querySelector(".first-line").textContent = data.discount + "%";
        element.querySelector(".second-line").textContent = "OFF";
        element.querySelector(".body").classList.add("visited");
    }

    return (
        <div>
            {
                props.data.map((element, index) => {
                    return (
                        <a
                            className="coupon-parent"
                            target="_blank"
                            rel="noreferrer"
                            href={element.link}
                            onClick={hideCouponRoot.bind(this, element)}
                            key={"discount-" + index}
                        >
                            <div className="coupon body">
                                <CouponBody title={element.name} text={element.text}/>
                            </div>
                            <div>
                                <div className="coupon-visit-wrapper">
                                    <span className="coupon-visit first-line">Visit</span>
                                    <span className="coupon-visit second-line">Site</span>
                                </div>
                                <div className="coupon root">
                                    <CouponRoot discount={element.discount + "%"} fill={element.color}/>
                                </div>
                            </div>
                        </a>
                    )
                })
            }
        </div>
    );
}

Discounts.propTypes = {
    data: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string.isRequired,
        discount: PropTypes.string.isRequired,
        text: PropTypes.string.isRequired,
        link: PropTypes.string.isRequired,
        color: PropTypes.string.isRequired,
    })),
};

export default Discounts
